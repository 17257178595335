// fallbacks for choropleth / bubbles that are specified in the config
export const DEFAULT_CHOROPLETH_COLORS = ["#f0f7f9", "#a5d5db", "#008097"];
export const DEFAULT_BUBBLE_COLOR = "#e98816";

// time series events colors
export const EVENT_COLORS = [
  "#F07300",
  "#666666",
  "#CE0000",
  "#a953cf",
  "#0000CE",
];

export const TIME_COMPARISON_LINE_COLORS = [
  "#CCCCCC",
  "#9DC58F",
  "#A57A9F",
  "#8f9dc5",
  "#323232",
];

export const EVICTION_DATA_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

// metrics to show in the tooltip (TODO: move to config props)
export const TOOLTIP_METRICS = ["ef", "efr", "rhh", "mfa", "tfa"];

export const ALL_DATA_COLOR = "#ABC2C1";

export const TARRANT_COUNTY_IDS = [
  "4804000",
  "4805168",
  "4807132",
  "4807552",
  "4808860",
  "4810192",
  "4811428",
  "4815988",
  "4817960",
  "4819084",
  "4822588",
  "4824768",
  "4824912",
  "4826232",
  "4826544",
  "4827000",
  "4830464",
  "4830644",
  "4831928",
  "4832720",
  "4835576",
  "4838632",
  "4838896",
  "4840744",
  "4841056",
  "4846452",
  "4850772",
  "4852356",
  "4855020",
  "4856462",
  "4856640",
  "4861568",
  "4861604",
  "4861844",
  "4862384",
  "4864112",
  "4865660",
  "4869032",
  "4873710",
  "4876672",
  "4878076",
  "4878544",
  "484391",
  "484392",
  "484393",
  "484394",
  "484395",
  "484396",
  "484397",
  "484398",
  "48439114104",
  "48439106517",
  "48439113908",
  "48439113909",
  "48439111407",
  "48439113632",
  "48439106004",
  "48439113926",
  "48439121605",
  "48439111546",
  "48439113709",
  "48439113923",
  "48439111522",
  "48439111311",
  "48439121604",
  "48439111018",
  "48439111514",
  "48439111008",
  "48439113404",
  "48439106518",
  "48439113813",
  "48439113917",
  "48439105006",
  "48439111015",
  "48439110203",
  "48439111404",
  "48439106001",
  "48439110905",
  "48439111310",
  "48439113809",
  "48439123600",
  "48439110402",
  "48439105008",
  "48439111406",
  "48439111543",
  "48439100602",
  "48439111516",
  "48439114203",
  "48439122400",
  "48439114007",
  "48439122300",
  "48439113519",
  "48439111314",
  "48439111539",
  "48439113109",
  "48439102601",
  "48439111506",
  "48439111203",
  "48439110102",
  "48439113218",
  "48439113216",
  "48439106514",
  "48439111541",
  "48439110906",
  "48439110805",
  "48439104601",
  "48439106700",
  "48439104202",
  "48439106300",
  "48439103601",
  "48439102202",
  "48439110701",
  "48439113405",
  "48439104603",
  "48439105204",
  "48439110903",
  "48439105703",
  "48439105405",
  "48439104100",
  "48439100900",
  "48439100601",
  "48439101302",
  "48439105514",
  "48439113114",
  "48439113812",
  "48439111547",
  "48439104604",
  "48439122200",
  "48439122801",
  "48439105403",
  "48439111549",
  "48439111533",
  "48439122900",
  "48439113803",
  "48439113710",
  "48439113626",
  "48439104503",
  "48439111308",
  "48439103602",
  "48439114205",
  "48439123500",
  "48439111202",
  "48439113610",
  "48439113510",
  "48439111525",
  "48439113518",
  "48439114204",
  "48439106516",
  "48439113102",
  "48439100502",
  "48439113630",
  "48439113511",
  "48439113634",
  "48439111521",
  "48439105406",
  "48439102100",
  "48439111538",
  "48439113927",
  "48439121906",
  "48439111542",
  "48439122500",
  "48439113613",
  "48439110204",
  "48439123000",
  "48439102401",
  "48439110807",
  "48439104504",
  "48439106509",
  "48439114003",
  "48439113811",
  "48439105902",
  "48439121609",
  "48439113911",
  "48439113217",
  "48439121905",
  "48439114207",
  "48439111016",
  "48439105513",
  "48439100400",
  "48439113808",
  "48439113611",
  "48439111409",
  "48439101401",
  "48439106507",
  "48439113633",
  "48439113910",
  "48439113113",
  "48439113618",
  "48439111536",
  "48439106515",
  "48439113111",
  "48439113513",
  "48439100700",
  "48439113116",
  "48439111529",
  "48439113816",
  "48439113631",
  "48439113907",
  "48439101201",
  "48439110600",
  "48439122001",
  "48439113214",
  "48439105201",
  "48439104605",
  "48439111523",
  "48439123100",
  "48439113001",
  "48439113912",
  "48439103701",
  "48439113622",
  "48439106511",
  "48439113213",
  "48439113810",
  "48439113514",
  "48439123200",
  "48439111405",
  "48439113924",
  "48439113212",
  "48439100101",
  "48439122100",
  "48439113921",
  "48439113302",
  "48439105600",
  "48439102500",
  "48439106101",
  "48439122002",
  "48439111309",
  "48439106202",
  "48439113407",
  "48439106503",
  "48439113520",
  "48439121608",
  "48439113206",
  "48439100201",
  "48439105203",
  "48439101403",
  "48439111544",
  "48439111012",
  "48439105704",
  "48439113814",
  "48439104803",
  "48439110809",
  "48439113221",
  "48439100501",
  "48439123400",
  "48439105404",
  "48439111005",
  "48439122802",
  "48439111513",
  "48439111306",
  "48439110704",
  "48439111003",
  "48439121704",
  "48439106502",
  "48439106600",
  "48439111532",
  "48439101700",
  "48439105505",
  "48439110401",
  "48439105510",
  "48439110703",
  "48439110302",
  "48439111104",
  "48439121904",
  "48439113108",
  "48439113629",
  "48439104502",
  "48439113207",
  "48439122600",
  "48439100300",
  "48439101402",
  "48439104400",
  "48439106510",
  "48439113612",
  "48439102800",
  "48439113220",
  "48439104602",
  "48439111010",
  "48439111552",
  "48439110901",
  "48439113607",
  "48439113919",
  "48439113925",
  "48439111011",
  "48439106400",
  "48439121601",
  "48439111548",
  "48439104802",
  "48439105508",
  "48439121702",
  "48439103702",
  "48439113918",
  "48439113512",
  "48439101301",
  "48439104702",
  "48439111204",
  "48439113703",
  "48439114008",
  "48439111402",
  "48439113705",
  "48439102201",
  "48439110202",
  "48439104201",
  "48439106512",
  "48439111304",
  "48439102402",
  "48439121610",
  "48439113115",
  "48439111537",
  "48439113509",
  "48439106002",
  "48439113107",
  "48439111540",
  "48439105502",
  "48439114206",
  "48439111545",
  "48439113002",
  "48439110907",
  "48439111534",
  "48439105507",
  "48439113301",
  "48439110101",
  "48439113210",
  "48439102700",
  "48439111505",
  "48439111553",
  "48439104300",
  "48439102000",
  "48439123300",
  "48439105205",
  "48439104505",
  "48439100202",
  "48439111530",
  "48439113403",
  "48439113711",
  "48439111312",
  "48439113625",
  "48439113516",
  "48439111551",
  "48439105007",
  "48439113627",
  "48439101202",
  "48439111013",
  "48439111307",
  "48439113929",
  "48439106201",
  "48439102301",
  "48439100102",
  "48439105701",
  "48439113110",
  "48439113922",
  "48439111017",
  "48439113112",
  "48439100800",
  "48439121611",
  "48439113916",
  "48439113517",
  "48439113619",
  "48439113906",
  "48439113628",
  "48439114103",
  "48439105001",
  "48439105503",
  "48439105511",
  "48439106102",
  "48439113408",
  "48439101500",
  "48439105800",
  "48439113104",
  "48439121903",
  "48439105512",
  "48439113815",
  "48439111550",
  "48439103500",
  "48439110301",
  "48439113707",
  "48439104900",
  "48439111103",
  "48439105901",
  "48439111313",
  "48439111102",
  "48439110500",
  "48439102302",
  "48439102602",
  "48439103800",
  "48439104804",
  "48439113624",
  "48439111301",
  "48439111526",
  "48439113928",
  "48439122700",
  "48439114005",
  "48439110808",
  "48439121606",
  "48439106513",
  "48439114006",
  "48439104701",
  "48439110806",
  "48439121703",
  "48439114102",
  "48439113215",
  "48439111531",
  "48439111408",
  "48439111524",
  "48439113920",
  "76105",
  "76114",
  "75054",
  "76120",
  "76112",
  "76001",
  "76104",
  "76053",
  "76110",
  "76102",
  "76182",
  "76148",
  "76092",
  "75028",
  "76103",
  "76132",
  "76014",
  "76179",
  "76155",
  "76034",
  "76116",
  "76126",
  "76135",
  "76054",
  "75050",
  "76123",
  "76109",
  "76262",
  "76248",
  "76117",
  "76108",
  "76021",
  "76051",
  "76244",
  "76052",
  "76017",
  "76180",
  "76006",
  "76020",
  "76040",
  "76063",
  "76137",
  "76010",
  "76018",
  "76060",
  "76012",
  "76039",
  "76011",
  "76016",
  "76140",
  "76115",
  "76022",
  "76119",
  "75052",
  "76134",
  "76133",
  "76028",
  "76131",
  "76106",
  "76118",
  "76013",
  "76015",
  "76002",
  "75051",
  "76164",
  "76107",
  "76008",
  "76111",
  "76071",
  "76036",
  "76177",
];
