import React from "react";
import { SvgIcon } from "@material-ui/core";

const MapIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M5 3C3.89 3 3 3.89 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3H5ZM15.78 5H19V17.18C18.74 16.38 17.69 15.79 16.8 15.79H15.8V12.79C15.8 12.5248 15.6946 12.2704 15.5071 12.0829C15.3196 11.8954 15.0652 11.79 14.8 11.79H8.8V9.79H10.8C11.0652 9.79 11.3196 9.68464 11.5071 9.49711C11.6946 9.30957 11.8 9.05522 11.8 8.79V6.79H13.8C14.83 6.79 15.67 6 15.78 5ZM5 10.29L9.8 14.79V15.79C9.8 16.3204 10.0107 16.8291 10.3858 17.2042C10.7609 17.5793 11.2696 17.79 11.8 17.79V19H5V10.29Z" />
    </SvgIcon>
  );
};

export default MapIcon;
