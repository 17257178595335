import React from "react";
import { SvgIcon } from "@material-ui/core";

const HiddenIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9571 2.71004C21.3476 3.10057 21.3476 3.73373 20.9571 4.12426L18.6185 6.46287C21.4321 8.64926 23 11.5 23 11.5C23 11.5 18.875 19 12 19C10.2457 19 8.67048 18.5117 7.29715 17.7842L4.45711 20.6243C4.06658 21.0148 3.43342 21.0148 3.04289 20.6243C2.65237 20.2337 2.65237 19.6006 3.04289 19.21L5.571 16.6819C2.6384 14.4789 1 11.5 1 11.5C1 11.5 5.125 4 12 4C13.8473 4 15.496 4.54147 16.9195 5.33343L19.5429 2.71004C19.9334 2.31952 20.5666 2.31952 20.9571 2.71004ZM12 6.72727C12.9704 6.72727 13.9106 7.01795 14.7023 7.55068L13.7102 8.54275C13.1941 8.24923 12.6053 8.09091 12 8.09091C11.0883 8.09091 10.214 8.45008 9.56932 9.08941C8.92466 9.72874 8.5625 10.5959 8.5625 11.5C8.5625 12.1099 8.72728 12.7029 9.0324 13.2205L8.04033 14.2126C7.48881 13.4208 7.1875 12.4758 7.1875 11.5C7.1875 10.2342 7.69453 9.02023 8.59705 8.12517C9.49957 7.23011 10.7236 6.72727 12 6.72727ZM10.5094 14.5719L9.50196 15.5794C10.2488 16.0292 11.1117 16.2727 12 16.2727C13.2764 16.2727 14.5004 15.7699 15.403 14.8748C16.3055 13.9798 16.8125 12.7658 16.8125 11.5C16.8125 10.6057 16.5594 9.73722 16.0925 8.98883L15.085 9.99632C15.3148 10.4599 15.4375 10.9737 15.4375 11.5C15.4375 12.4041 15.0753 13.2713 14.4307 13.9106C13.786 14.5499 12.9117 14.9091 12 14.9091C11.479 14.9091 10.9702 14.7918 10.5094 14.5719Z"
      />
    </SvgIcon>
  );
};

export default HiddenIcon;
