import { Box } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";

const BrandingBox = withStyles((theme) => ({
  root: {
    "@media(max-width: 480px)": {
      display: "none",
    },
  },
}))(Box);

const Branding = (props) => {
  return (
    <BrandingBox display="flex" alignItems="center" {...props}>
      <Box component="a" display="inline-block" href="/">
        <svg
          width="119"
          height="32"
          viewBox="0 0 119 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby="ntepTitle"
          style={{ display: "block" }}
        >
          <title id="ntepTitle">North Texas Eviction Project</title>
          <path
            d="M6.79402 12.5H9.49402V1.69997H7.60402V5.44397C7.60402 6.86597 7.69402 8.84597 7.80202 10.376L7.47802 10.394C6.99202 9.15197 6.18202 7.33397 5.39002 5.92997L3.01402 1.69997H0.0800171V12.5H2.00602V8.79197C2.00602 7.36997 1.91602 5.10197 1.80802 3.53597L2.13202 3.49997C2.67202 4.79597 3.42802 6.48797 4.02202 7.58597L6.79402 12.5Z"
            fill="#D9D9C4"
          />
          <path
            d="M15.408 12.734C18.09 12.734 19.674 11.024 19.674 8.25197C19.674 5.46197 18 3.82397 15.408 3.82397C12.78 3.82397 11.106 5.46197 11.106 8.25197C11.106 11.024 12.69 12.734 15.408 12.734ZM15.39 5.46197C16.956 5.46197 17.748 6.48797 17.748 8.23397C17.748 9.97997 16.992 11.06 15.39 11.06C13.77 11.06 13.05 9.97997 13.05 8.23397C13.05 6.48797 13.824 5.46197 15.39 5.46197Z"
            fill="#D9D9C4"
          />
          <path
            d="M23.1332 12.5V8.48597C23.1332 6.75797 23.7632 6.12797 25.5452 6.12797H26.0132V3.94997H25.7072C24.4832 3.94997 23.6912 4.54397 23.2052 6.59597L23.0432 6.57797V4.03997H21.2072V12.5H23.1332Z"
            fill="#D9D9C4"
          />
          <path
            d="M27.8104 9.92597C27.8104 11.51 28.6024 12.734 30.4564 12.734C31.3024 12.734 31.7344 12.626 32.3644 12.428L32.1304 10.7C31.7164 10.826 31.3384 10.916 30.9064 10.916C30.0604 10.916 29.7544 10.484 29.7544 9.65597V5.60597H32.2384V4.03997H29.7544V1.75397H27.8104V4.03997H26.6224V5.60597H27.8104V9.92597Z"
            fill="#D9D9C4"
          />
          <path
            d="M35.6664 7.71197C35.6664 6.43397 36.5304 5.56997 37.8444 5.56997C38.9784 5.56997 39.4464 6.32597 39.4464 7.35197V12.5H41.3724V6.84797C41.3724 5.01197 40.5444 3.82397 38.7984 3.82397C37.3224 3.82397 36.2964 4.72397 35.8104 6.27197H35.6664V0.94397H33.7404V12.5H35.6664V7.71197Z"
            fill="#D9D9C4"
          />
          <path
            d="M45.9571 3.58997H49.2871V12.5H51.3391V3.58997H54.6691V1.69997H45.9571V3.58997Z"
            fill="#D9D9C4"
          />
          <path
            d="M59.65 5.31797C61 5.31797 61.648 6.07397 61.72 7.53197H57.382C57.562 6.01997 58.336 5.31797 59.65 5.31797ZM59.704 12.716C61.972 12.716 63.034 11.672 63.43 10.142L61.774 9.67397C61.54 10.484 61.072 11.132 59.722 11.132C58.282 11.132 57.508 10.358 57.364 8.82797H63.466C63.502 8.39597 63.502 8.01797 63.502 7.69397C63.502 5.37197 62.26 3.82397 59.704 3.82397C57.058 3.82397 55.438 5.51597 55.438 8.37797C55.438 11.204 57.112 12.716 59.704 12.716Z"
            fill="#D9D9C4"
          />
          <path
            d="M70.4827 12.5H72.6607V12.302L69.9607 8.37797L72.5887 4.23797V4.03997H70.5547L68.7187 7.53197H68.5027L66.5407 4.03997H64.3447V4.23797L67.0447 8.10797L64.3267 12.302V12.5H66.4507L68.2687 8.93597H68.5207L70.4827 12.5Z"
            fill="#D9D9C4"
          />
          <path
            d="M76.8203 11.168C75.7223 11.168 75.3443 10.646 75.3443 10.034C75.3443 9.33197 75.9023 8.97197 76.8743 8.97197H79.0703V9.31397C79.0703 10.376 78.1703 11.168 76.8203 11.168ZM79.1603 12.5H80.9963V7.09997C80.9963 4.99397 79.9523 3.84197 77.5043 3.84197C75.3443 3.84197 74.1383 4.86797 73.8143 6.46997L75.6503 6.88397C75.8663 5.89397 76.4423 5.38997 77.4323 5.38997C78.6203 5.38997 79.0703 6.01997 79.0703 7.11797V7.69397H76.5503C74.4083 7.69397 73.4903 8.79197 73.4903 10.304C73.4903 11.96 74.5523 12.68 76.0643 12.68C77.5583 12.68 78.5303 11.852 78.9623 10.484H79.1603V12.5Z"
            fill="#D9D9C4"
          />
          <path
            d="M86.3072 12.734C88.7372 12.734 89.7812 11.726 89.7812 10.16C89.7812 8.82797 89.0252 7.98197 86.5232 7.51397C84.9212 7.22597 84.5612 6.81197 84.5612 6.21797C84.5612 5.58797 85.0652 5.20997 86.0552 5.20997C87.0632 5.20997 87.5672 5.60597 87.8012 6.64997L89.5472 6.27197C89.2052 4.77797 88.4132 3.82397 86.0912 3.82397C83.8412 3.82397 82.7252 4.79597 82.7252 6.32597C82.7252 7.72997 83.5172 8.48597 85.7672 8.93597C87.4412 9.24197 87.9092 9.56597 87.9092 10.232C87.9092 10.952 87.3692 11.312 86.3432 11.312C84.9752 11.312 84.3812 10.664 84.1112 9.63797L82.3652 9.99797C82.6712 11.618 83.5712 12.734 86.3072 12.734Z"
            fill="#D9D9C4"
          />
          <path
            d="M0.0800171 28.5H7.85602V26.718H2.13202V23.91H7.33402V22.146H2.13202V19.482H7.87402V17.7H0.0800171V28.5Z"
            fill="#D9D9C4"
          />
          <path
            d="M14.3011 28.5L17.4151 20.04H15.3631L14.3191 23.316C13.9411 24.486 13.5451 25.692 13.2211 26.844H12.8611C12.5371 25.692 12.1411 24.522 11.7631 23.316L10.7191 20.04H8.61314L11.7451 28.5H14.3011Z"
            fill="#D9D9C4"
          />
          <path
            d="M20.4664 18.6V17.034H18.3604V18.6H20.4664ZM20.3764 28.5V20.04H18.4324V28.5H20.3764Z"
            fill="#D9D9C4"
          />
          <path
            d="M28.3576 23.334L30.1756 22.992C29.8336 20.976 28.7356 19.824 26.3056 19.824C23.7136 19.824 22.0396 21.498 22.0396 24.342C22.0396 27.186 23.6776 28.734 26.3416 28.734C28.6636 28.734 29.8516 27.546 30.1936 25.584L28.4116 25.152C28.1596 26.178 27.6916 27.024 26.3236 27.024C24.8116 27.024 24.0196 25.98 24.0196 24.27C24.0196 22.416 24.8836 21.534 26.2696 21.534C27.6016 21.534 28.1056 22.254 28.3576 23.334Z"
            fill="#D9D9C4"
          />
          <path
            d="M32.2401 25.926C32.2401 27.51 33.0321 28.734 34.8861 28.734C35.7321 28.734 36.1641 28.626 36.7941 28.428L36.5601 26.7C36.1461 26.826 35.7681 26.916 35.3361 26.916C34.4901 26.916 34.1841 26.484 34.1841 25.656V21.606H36.6681V20.04H34.1841V17.754H32.2401V20.04H31.0521V21.606H32.2401V25.926Z"
            fill="#D9D9C4"
          />
          <path
            d="M40.3121 18.6V17.034H38.2061V18.6H40.3121ZM40.2221 28.5V20.04H38.2781V28.5H40.2221Z"
            fill="#D9D9C4"
          />
          <path
            d="M46.1873 28.734C48.8693 28.734 50.4533 27.024 50.4533 24.252C50.4533 21.462 48.7793 19.824 46.1873 19.824C43.5593 19.824 41.8853 21.462 41.8853 24.252C41.8853 27.024 43.4693 28.734 46.1873 28.734ZM46.1693 21.462C47.7353 21.462 48.5273 22.488 48.5273 24.234C48.5273 25.98 47.7713 27.06 46.1693 27.06C44.5493 27.06 43.8293 25.98 43.8293 24.234C43.8293 22.488 44.6033 21.462 46.1693 21.462Z"
            fill="#D9D9C4"
          />
          <path
            d="M53.9125 23.712C53.9125 22.434 54.7765 21.57 56.0725 21.57C57.2425 21.57 57.6925 22.326 57.6925 23.352V28.5H59.6185V22.848C59.6185 21.012 58.7725 19.824 57.0085 19.824C55.5865 19.824 54.5065 20.706 54.0385 22.272H53.8765V20.04H51.9865V28.5H53.9125V23.712Z"
            fill="#D9D9C4"
          />
          <path
            d="M69.1892 19.446C70.7912 19.446 71.3492 20.04 71.3492 21.354C71.3492 22.65 70.7192 23.244 69.2072 23.244H67.0832V19.446H69.1892ZM69.4412 24.936C71.9792 24.936 73.3652 23.388 73.3652 21.336C73.3652 18.924 72.0512 17.7 69.3692 17.7H65.0312V28.5H67.0832V24.936H69.4412Z"
            fill="#D9D9C4"
          />
          <path
            d="M76.5883 28.5V24.486C76.5883 22.758 77.2183 22.128 79.0003 22.128H79.4683V19.95H79.1623C77.9383 19.95 77.1463 20.544 76.6603 22.596L76.4983 22.578V20.04H74.6623V28.5H76.5883Z"
            fill="#D9D9C4"
          />
          <path
            d="M84.5955 28.734C87.2775 28.734 88.8615 27.024 88.8615 24.252C88.8615 21.462 87.1875 19.824 84.5955 19.824C81.9675 19.824 80.2935 21.462 80.2935 24.252C80.2935 27.024 81.8775 28.734 84.5955 28.734ZM84.5775 21.462C86.1435 21.462 86.9355 22.488 86.9355 24.234C86.9355 25.98 86.1795 27.06 84.5775 27.06C82.9575 27.06 82.2375 25.98 82.2375 24.234C82.2375 22.488 83.0115 21.462 84.5775 21.462Z"
            fill="#D9D9C4"
          />
          <path
            d="M92.6267 28.302V20.058H90.7007V28.536C90.7007 29.256 90.4667 29.724 89.8007 29.724C89.5847 29.724 89.3687 29.688 89.1527 29.652L89.0627 31.326C89.2967 31.38 89.5667 31.416 89.8727 31.416C91.8347 31.416 92.6267 30.192 92.6267 28.302ZM92.7167 18.6V17.034H90.6107V18.6H92.7167Z"
            fill="#D9D9C4"
          />
          <path
            d="M98.4977 21.318C99.8477 21.318 100.496 22.074 100.568 23.532H96.2297C96.4097 22.02 97.1837 21.318 98.4977 21.318ZM98.5517 28.716C100.82 28.716 101.882 27.672 102.278 26.142L100.622 25.674C100.388 26.484 99.9197 27.132 98.5697 27.132C97.1297 27.132 96.3557 26.358 96.2117 24.828H102.314C102.35 24.396 102.35 24.018 102.35 23.694C102.35 21.372 101.108 19.824 98.5517 19.824C95.9057 19.824 94.2857 21.516 94.2857 24.378C94.2857 27.204 95.9597 28.716 98.5517 28.716Z"
            fill="#D9D9C4"
          />
          <path
            d="M109.744 23.334L111.562 22.992C111.22 20.976 110.122 19.824 107.692 19.824C105.1 19.824 103.426 21.498 103.426 24.342C103.426 27.186 105.064 28.734 107.728 28.734C110.05 28.734 111.238 27.546 111.58 25.584L109.798 25.152C109.546 26.178 109.078 27.024 107.71 27.024C106.198 27.024 105.406 25.98 105.406 24.27C105.406 22.416 106.27 21.534 107.656 21.534C108.988 21.534 109.492 22.254 109.744 23.334Z"
            fill="#D9D9C4"
          />
          <path
            d="M113.627 25.926C113.627 27.51 114.419 28.734 116.273 28.734C117.119 28.734 117.551 28.626 118.181 28.428L117.947 26.7C117.533 26.826 117.155 26.916 116.723 26.916C115.877 26.916 115.571 26.484 115.571 25.656V21.606H118.055V20.04H115.571V17.754H113.627V20.04H112.439V21.606H113.627V25.926Z"
            fill="#D9D9C4"
          />
        </svg>
      </Box>
      <Box
        component="a"
        target="_blank"
        rel="noopener noreferrer"
        href="https://childpovertyactionlab.org/"
      >
        <img
          src="assets/cpal-logo.png"
          width="52"
          style={{ marginLeft: 16, opacity: 0.7 }}
          alt="Child Poverty Action Lab"
        />
      </Box>
    </BrandingBox>
  );
};

Branding.propTypes = {};

export default Branding;
