import React from "react";
import { SvgIcon } from "@material-ui/core";

const SearchIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M10.27 17.5392C11.883 17.5389 13.4495 16.9989 14.7202 16.0053L18.715 20L20 18.7151L16.0051 14.7204C16.9993 13.4498 17.5396 11.883 17.54 10.2696C17.54 6.26133 14.2785 3 10.27 3C6.26151 3 3 6.26133 3 10.2696C3 14.2779 6.26151 17.5392 10.27 17.5392ZM10.27 4.8174C13.2771 4.8174 15.7225 7.26272 15.7225 10.2696C15.7225 13.2765 13.2771 15.7218 10.27 15.7218C7.26295 15.7218 4.8175 13.2765 4.8175 10.2696C4.8175 7.26272 7.26295 4.8174 10.27 4.8174Z" />
    </SvgIcon>
  );
};

export default SearchIcon;
