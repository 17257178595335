import React from "react";
import { SvgIcon } from "@material-ui/core";

const VisibleIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M15.4375 11.5C15.4375 12.4041 15.0753 13.2713 14.4307 13.9106C13.786 14.5499 12.9117 14.9091 12 14.9091C11.0883 14.9091 10.214 14.5499 9.56932 13.9106C8.92466 13.2713 8.5625 12.4041 8.5625 11.5C8.5625 10.5959 8.92466 9.72874 9.56932 9.08941C10.214 8.45008 11.0883 8.09091 12 8.09091C12.9117 8.09091 13.786 8.45008 14.4307 9.08941C15.0753 9.72874 15.4375 10.5959 15.4375 11.5V11.5Z" />
      <path d="M1 11.5C1 11.5 5.125 4 12 4C18.875 4 23 11.5 23 11.5C23 11.5 18.875 19 12 19C5.125 19 1 11.5 1 11.5ZM12 16.2727C13.2764 16.2727 14.5004 15.7699 15.403 14.8748C16.3055 13.9798 16.8125 12.7658 16.8125 11.5C16.8125 10.2342 16.3055 9.02023 15.403 8.12517C14.5004 7.23011 13.2764 6.72727 12 6.72727C10.7236 6.72727 9.49957 7.23011 8.59705 8.12517C7.69453 9.02023 7.1875 10.2342 7.1875 11.5C7.1875 12.7658 7.69453 13.9798 8.59705 14.8748C9.49957 15.7699 10.7236 16.2727 12 16.2727V16.2727Z" />
    </SvgIcon>
  );
};

export default VisibleIcon;
