import React from "react";
import { SvgIcon } from "@material-ui/core";

const ExpandIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.99999 19L1.99999 5C1.99999 4.73478 2.10535 4.48043 2.29289 4.29289C2.48042 4.10536 2.73478 4 2.99999 4L13 4C13.2652 4 13.5196 4.10535 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H2.99999C2.73478 20 2.48042 19.8946 2.29289 19.7071C2.10535 19.5196 1.99999 19.2652 1.99999 19ZM3.99999 18L12 18V6L3.99999 6L3.99999 18Z"
      />
      <path d="M17 4C17.2652 4 17.5196 4.10535 17.7071 4.29289C17.8946 4.48043 18 4.73478 18 5V8L16 6L16 4L17 4Z" />
      <path d="M18 16V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H16L16 18L18 16Z" />
      <path d="M22 19V12.4142L20 14.4142V20H21C21.2652 20 21.5196 19.8946 21.7071 19.7071C21.8946 19.5196 22 19.2652 22 19Z" />
      <path d="M20 9.58579L22 11.5858V5C22 4.73478 21.8946 4.48043 21.7071 4.29289C21.5196 4.10535 21.2652 4 21 4L20 4V9.58579Z" />
      <path d="M20 12L16 8V16L20 12Z" />
    </SvgIcon>
  );
};

export default ExpandIcon;
